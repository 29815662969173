import './App.css';
import { useState } from 'react';

const API__KEY = '92ff16d672a3009ad249048dd6a5a9ac';

function App() {
  const [city, setCity] = useState('');
  const [weather, setWeather] = useState({});
  const now = new Date();

  const optionDate = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }

  const onClickSearchWeather = async (event) => {
    event.preventDefault();
    const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${API__KEY}&lang=ru&units=metric&cnt=16`);
    const data = await response.json();
    setWeather(data);
  }

  return (
    <div className="weather">
      <div className="top__menu">
        <form className='weather__form'>
          <input className='weather__input' type="text" placeholder='Введите ваш город' onChange={(event) => setCity(event.target.value)} />
          <button className='weather__button' type='submit' onClick={onClickSearchWeather}>
            <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 6C13.7614 6 16 8.23858 16 11M16.6588 16.6549L21 21M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </button>
        </form>
        <div className="degress">
          <div className="celsia">°C</div>
          <div className="farengeit">°F</div>
        </div>
      </div>
      <div className="main__content">
        {weather.name !== undefined ? (
          <>
            <h2 className='weather__city'>{weather.name}</h2>
            <p className='date'>Сегодня, {now.toLocaleString("ru", optionDate)}</p>
            <p className='weather__info'>{weather.weather[0].description}</p>
            <p className='weather__temp'>{Math.round(weather.main.temp)}°C</p>
            <div className="min-max__temp">
              <p className='min-max'>Мин: {Math.round(weather.main.temp_min)}°C</p>
              <p className='min-max'>Макс: {Math.round(weather.main.temp_max)}°C</p>
            </div>
          </>
        ) : null}
      </div>
      <div className="additional-information">
        {weather.name !== undefined ? (
          <>
            <div className="additional-information__card">
              <img src="termometr.svg" alt="" />
              <div className="card-desc__wrapper">
                <p className='card-title'>По ощущениям</p>
                <p className='card-value'>{Math.round(weather.main.feels_like)}°C</p>
              </div>
            </div>
            <div className="additional-information__card">
              <img src="pressure.svg" alt="" />
              <div className="card-desc__wrapper">
                <p className='card-title'>Давление</p>
                <p className='card-value'>{Math.round(weather.main.pressure * 0.75006)}</p>
              </div>
            </div>
            <div className="additional-information__card">
              <img src="weather.svg" alt="" />
              <div className="card-desc__wrapper">
                <p className='card-title'>Ветер</p>
                <p className='card-value'>{Math.round(weather.wind.speed)} м/с</p>
              </div>
            </div>
            <div className="additional-information__card">
              <img src="drop.svg" alt="" />
              <div className="card-desc__wrapper">
                <p className='card-title'>Влажность</p>
                <p className='card-value'>{Math.round(weather.main.humidity)}%</p>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default App;
